import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';


const Product = () => {

    return (
        <div className='pt-12' >
            <div className='container'>
                <div className='product' id='products'>
                    <h1 className='text-[20px] sm:text-[30px] md:text-[30px]  font-semibold   text-center max-w-2xl mx-auto pb-10'>Our Product</h1>
                </div>
                <div className='banner-slider-wrapper'>
                    <div className='banner-slider-inner'>
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={2}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            autoplay={{ delay: 3000 }}
                            onSlideChange={() => console.log('slide change')}
                            breakpoints={{
                                // when window width is <= 640px (mobile view)
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                },
                                // when window width is > 640px
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className='banner-image '>
                                    <Link to='/herbs'>
                                        <div className='banner-image-inner'>
                                            <div class="product-item">
                                                <img src='../Assets/img/herbs.png' alt='home-banner-slider' />
                                                <div class="content-box">
                                                    HERBS & PULSES & COFFEE
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className='banner-image '>
                                <Link to='/spicesspicel'>
                                    <div className='banner-image-inner'>
                                        <div class="product-item">
                                            <img src='../Assets/img/spices3.png' alt='home-banner-slider' />
                                            <div class="content-box">
                                            SPICES
                                            </div> 
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                            <SwiperSlide>
                                <div className='banner-image '>
                                    <Link to='fruits'>
                                        <div className='banner-image-inner'>
                                            <div class="product-item">
                                                <img src='../Assets/img/dyfruits.png' alt='home-banner-slider' />
                                                <div class="content-box">
                                                    FRUITS & DRYFRUITS
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='banner-image'>
                                    <Link to='/vegetables'>
                                        <div className='banner-image-inner'>
                                            <div class="product-item">
                                                <img src='../Assets/img/vehetable.png' alt='home-banner-slider' />
                                                <div class="content-box">
                                                    VEGETABLES
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='banner-image'>
                                    <Link to='/kitchen'>
                                        <div className='banner-image-inner '>
                                            <div class="product-item border border-1 border-gray-500">
                                                <img src='../Assets/img/jali.jpg' alt='home-banner-slider' />

                                                <div class="content-box">
                                                    kitchen Essentials
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product
