import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

// function ScrollToTop() {
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location.product]);
//   return null;
// }
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
      <div className="header bg-[#F2F6F6]  fixed w-full top-0 z-50  ">
        <div className='container my-0 mx-auto'>
          <nav className="py-5  relative" >
            <div className="flex flex-wrap  justify-between items-center mx-auto max-w-screen-xl">
              <div className='w-[200px] md:w-[200px] sm:w-[250px]  lg:w-[300px]' >
                <img src='../Assets/img/logo.png' width='200px' alt='logo'/>
              </div>
              <button
                onClick={toggleMenu}
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isMenuOpen ? 'true' : 'false'}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${isMenuOpen ? 'hidden' : ''} w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className={`${isMenuOpen ? '' : 'hidden'} w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div
                className={`${isMenuOpen ? 'block' : 'hidden'
                  } justify-between items-center w-full  lg:h-0 h-dvh lg:flex lg:w-auto lg:order-2`}
                id="mobile-menu-2"
              >
                <div>
                  <ul className="flex flex-col mt-8 font-medium lg:flex-row lg:space-x-8 lg:mt-0 text-white text-center">
                    <li>
                    <Link to='/'>
                      <a href='#home' onClick={() => {
                        toggleMenu();
                      }} className="block py-8 pr-4 pl-3 text-[20px] text-black   font-medium  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0   dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">Home</a>
                      </Link>
                    </li>
                    <li>     
                      <a href='#products' onClick={() => {
                        toggleMenu();
                      }} className="block py-8 pr-4 pl-3 text-[20px] text-black  font-medium lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0   dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"> 
                      Product
                      </a>
                    </li>
                    <li>
                      <a href='#about' onClick={() => {
                        toggleMenu();
                      }} className="block py-8 pr-4 pl-3 text-[20px] text-black   font-medium  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0  dark:hover:bg-gray-700  lg:dark:hover:bg-transparent dark:border-gray-700">About Us</a>
                    </li>
                    <li>
                      <a href='#contact' onClick={() => {
                        toggleMenu();
                      }} className="block py-8 pr-4 pl-3 text-[20px] text-black font-medium font-medium   lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0   dark:hover:bg-gray-700  lg:dark:hover:bg-transparent dark:border-gray-700">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
  )
}

export default Header