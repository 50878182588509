import './App.css';
import Header from './Components/CommonComponents/Header';
import Home from './Components/Home/Home';
import { Routes, Route, useLocation } from "react-router-dom"
import Footer from './Components/CommonComponents/Footer';
import SpicesSpiceal from './Components/ProductPopural/SpicesSpiceal';
import { useEffect } from 'react';
import Fruitsdryfruits from './Components/ProductPopural/Fruitsdryfruits';
import Herbs from './Components/ProductPopural/Herbs';
import Vegetables from './Components/ProductPopural/Vegetables';
import Kitchen from './Components/ProductPopural/Kitchen';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
function App() {

  return (
    <div>
    <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/spicesspicel" element={<SpicesSpiceal />} />
        <Route path="/fruits" element={<Fruitsdryfruits/>} />
        <Route path="/herbs" element={<Herbs />} />
        <Route path="/vegetables" element={<Vegetables />} />
        <Route path="/kitchen" element={<Kitchen />} />
      </Routes>
      <Footer />
    </div>

  );
}

export default App;
