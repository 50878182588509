import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y , Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
function Slider() {
    return (
        <div  id='slider'>
        <div className='lg:mt-48 md:mt-48 sm:mt-40 mt-32'>
            <h1 className='text-[16px] sm:text-[30px] md:text-[30px]  font-semibold   text-center max-w-2xl mx-auto pb-10'>PROFESSIONAL IMPORT
                EXPORT AND EXPORT SERVICES</h1>
            <div className='banner-slider-wrapper'>
                <div className='banner-slider-inner h-'>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay ]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        autoplay={{ delay: 3000 }}
                    >
                        <SwiperSlide>
                            <div className='banner-image'>
                                <div className='banner-image-inner '>
                                    <img src='../Assets/img/back1.jpg' width='100%' className='lg:h-[700px]' alt='home-banner-slider' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>  <div className='banner-image'>
                            <div className='banner-image-inner'>
                                <img src='../Assets/img/back2.jpg' width='100%' className='lg:h-[700px]' alt='home-banner-slider' />
                            </div>
                        </div></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Slider


