import React from 'react'
import Slider from './Slider'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import Product from './Product'

function Home() {
    return (
        <div id='home'> 
            <Slider />
            <Product/>
            <AboutUs />
            <ContactUs/>  
        </div>
    )
}

export default Home
