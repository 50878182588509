import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Footer() {
  const handleScrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds smooth scrolling effect
    });
  };
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <footer className="bg-gray-100 mt-24" data-aos="fade-up">
      <div className='container  mx-auto'>
        <div className="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 lg:pt-24">
          <div className="absolute end-4 top-4 sm:end-6 sm:top-6 lg:end-8 lg:top-8">
            <button
              className="inline-block rounded-full bg-teal-600 p-2 text-white shadow transition hover:bg-teal-500 sm:p-3 lg:p-4"
              onClick={handleScrollToTop}
            >
              <span className="sr-only">Back to top</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="lg:flex lg:items-end lg:justify-between">
            <div>
              <div className="flex justify-center text-teal-600 lg:justify-start">
                <img src='../Assets/img/logo.png' alt='logo' width='300px' className='md:h-35 md:w-60 h-16 w-52' />
              </div>
              <p className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500 lg:text-left">
                We provide our clients with time-saving economical solutions at extremely affordable rates.
              </p>
            </div>
            <ul
              className="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:mt-0 lg:justify-end lg:gap-12"
            >
              <li>
                <a href='#about' className="text-gray-700 transition hover:text-gray-700/75"  > About </a>
              </li>
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#product"> Product </a>
              </li>
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#contact">Contact </a>
              </li>
            </ul>
          </div>
          <p className="mt-12 text-center text-sm text-gray-500 ">
            Copyright &copy; 2024. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
