import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
function AboutUs() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div data-aos="zoom-in-down ">
      <div className='about pb-10 pt-20 ' id='about' >
        <div className='container  my-0 mx-auto'>
        <h1 className='text-[20px] pb-10 font-semibold text-center '>ABOUT US</h1>
          <div className=''>
            <img src='../Assets/img/boat.webp' width='100%' className='rounded-[10px]' alt='boat'></img>
          </div>
          <div className='pt-10'>  
            <p className='text-[16px] font-normal pt-3  text-justify '>We, Hayan Overseas company are a recognized name in the industry for trading of goods in and around the country. Our company has a strong base in area, city, state and have been offering our services since quite a few years now. Our team consists of a well experienced and trained staff, supported by fully computerized documentation with the latest communication equipment. We offer tailor made services to suit every customers' needs. Our employees are specially trained to understand every single client's requirements . It enables us to offer Import / Exports services as desired. We even offer best consultancy services.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
