import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
function ContactUs() {
    return (
        <div className='contactus my-24' id='contact'>
        <ScrollToTop/>
            <div className='container'>
                <h1 className='text-[20px] font-semibold text-center '  >CONTACT US </h1>
                <div className='grid grid-cols-1 md:grid-cols-2  lg:md:grid-cols-2 pt-10 gap-10'>
                    <div className=' w-full'>
                        <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.836891951371!2d72.88657767394068!3d21.238315580550736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f40eacb8b3d%3A0x5dd425eaf9ff081c!2sMBC%20-%20Meridian%20Business%20Center!5e0!3m2!1sen!2sin!4v1704449082968!5m2!1sen!2sin" className='md:h-[250px] md:w-[100%] lg:h-[400\\\\px] lg:w-[100%] h-[300px] w-[100%] ' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className=''>
                        <div className='flex  gap-3 text-left'>
                            <img src='../Assets/img/address.webp' className='md:h-6 md:w-6 lg:h-6 lg:w-6 h-10 w-10 items-center ' alt='address'/>
                            <div>
                                <h2 className='text-[20px] font-medium ' > Our Office Address</h2>
                                <p className='pt-2'> 231, MBC Lajamni Chowk, opp. Opera Business Hub, Shanti Niketan Society, Mota Varachha, Surat, Gujarat 394101</p>
                            </div>
                        </div>
                        <div className='flex  gap-3 text-left pt-6'>
                            <img src='../Assets/img/contact.webp' className='md:h-6 md:w-6 lg:h-6 lg:w-6 h-10 w-10 items-center ' alt='contact' />
                            <div>
                                <h2 className='text-[20px] font-medium'>Call Us</h2>
                                <p className='pt-2'>9512376485</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactUs
