import React from 'react'
import { Link } from 'react-router-dom'
import kit1 from '../../product/kitchen/img1.bmp';
import kit2 from '../../product/kitchen/removable basket.bmp';
import kit3 from '../../product/kitchen/bottle_basket.bmp';
import kit4 from '../../product/kitchen/thali_basket.bmp';
import kit5 from '../../product/kitchen/partition_basket.bmp';
import kit6 from '../../product/kitchen/shoes_basket.bmp';
import kit7 from '../../product/kitchen/cornertriple.bmp';
import kit8 from '../../product/kitchen/glass_basket.bmp';
import kit9 from '../../product/kitchen/pull_out_basket.bmp';
import kit10 from '../../product/kitchen/cutelery_basket.bmp';
import kit11 from '../../product/kitchen/cup_basket.bmp';
import kit12 from '../../product/kitchen/cupandsaucer_basket.bmp';
import kit13 from '../../product/kitchen/deetrayunit.bmp';
import kit14 from '../../product/kitchen/bananatray.bmp';
import kit15 from '../../product/kitchen/grain_trollry.bmp';
import kit16 from '../../product/kitchen/apple_asket.bmp';
import kit17 from '../../product/kitchen/plate_basket.bmp';
import kit18 from '../../product/kitchen/cornersingle.bmp';
import kit19 from '../../product/kitchen/panal.jpg';
import kit20 from '../../product/kitchen/carrousel_basket.bmp';
import kit21 from '../../product/kitchen/hook.bmp';



const Kitchen = () => {
    const products = [
        {
            id: 5,
            type: "Kitchen",
            items: [
                {
                    image: kit1,
                    title: "Plain Basket",

                },
                {
                    image: kit2,
                    title: "Removable Perforated Plain Basket",
                },
                {
                    image: kit3,
                    title: "Bottle Basket",
                },
                {
                    image: kit4,
                    title: "Thali Basket",
                },
                {
                    image: kit5,
                    title: "Partition Basket",
                },
                {
                    image: kit6,
                    title: "Shoes Basket",
                },
                {
                    image: kit7,
                    title: "Corner Triple",
                },
                {
                    image: kit8,
                    title: "Glass Basket",
                },
                {
                    image: kit9,
                    title: "Pull Out Basket",
                },
                {
                    image: kit10,
                    title: "cutlery Basket",
                },
                {
                    image: kit11,
                    title: "Cup and Saucer Basket",
                },
                {
                    image: kit12,
                    title: "Caup and Saucer Basket",
                },
                {
                    image: kit13,
                    title: "Dee Tray Unit",
                },
                {
                    image: kit14,
                    title: "banana Tray",
                },
                {
                    image: kit15,
                    title: "Grain Trolley",
                },
                {
                    image: kit16,
                    title: "Fruit Basket",
                },
                {
                    image: kit17,
                    title: "Plate Basket",
                },
                {
                    image: kit18,
                    title: "Corner Single",
                },
                {
                    image: kit19,
                    title: "Telescopic Channel",
                },
                {
                    image: kit20,
                    title: "Carrousel Tray Unit",
                },
                {
                    image: kit21,
                    title: "Hook Rack",
                },
            ],
        },
    ]
    return (
        <div className='pt-32'>
            <div className="common-outer">
                <div className="common-content">
                    <div className="title">Kitchen Essentials</div>
                    <div>
                        <Link to="/">Home</Link> <span>
                            <i class="fa-solid fa-angle-right"></i>
                        </span>Kitchen Essentials
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="item row justyfy-content-center grid  lg:grid-cols-4 sm:grid-cols-3 grid-cols-1    lg:gap-6 md:gap-6 sm:gap-1 gap-2 ">
                    {products?.map((category) => (
                        category.items.map((item) => (
                            <div key={item.title} className="item flex flex-wrap " >
                                <div className="product-item">
                                    <div className="image-block">
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                    <div className="content-box">
                                        {item.title}
                                    </div>
                                </div>
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Kitchen
